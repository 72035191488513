@import '../../../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-icons/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-lists/styles/bootstrap5.css';
/* You can add global styles to this file, and also import other style files */
@import '../../../node_modules/@syncfusion/ej2-angular-popups/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-angular-inputs/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-angular-dropdowns/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-calendars/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-angular-calendars/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-richtexteditor/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-angular-notifications/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-querybuilder/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-angular-grids/styles/bootstrap5.css';
@import 'projects/asol-controls/asol-variables.scss';
@import '../../../node_modules/@syncfusion/ej2-notifications/styles/bootstrap5.css';

.custom-language-input {
  &.e-input-group {
    background-color: transparent;
    border: transparent;
  }
  .e-input-group-icon {
    color: $white !important;
  }
}

#global-progress-container {
  .e-spinner-pane {
    background-color: rgba(255, 255, 255, 0.8);
    // z-index: 10005;
    // .e-spinner-inner .e-spin-bootstrap5 {
    //   stroke: $black;
    //   .e-path-circle {
    //     stroke-width: spacer(0);
    //   }
    // }
  }
}
