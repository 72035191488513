:root {
  --logo-primary: #fff;
  --stepper-color: #2684ff;
}

$white: #fff !default;
$black: #333842 !default;
$black-dark: #000 !default;

$default-dark: #0d2443;

$blue-50: #deebff !default;
$blue-75: #b3d4ff !default;
$blue-100: #4c9aff !default;
$blue-200: #2684ff !default;
$blue-300: #0065ff !default;
$blue-400: #0052cc !default;
$blue-500: #0747a6 !default;

$neutral-10: #fafbfc !default;
$neutral-20: #f4f5f7 !default;
$neutral-30: #ebecf0 !default;
$neutral-40: #dfe1e6 !default;
$neutral-50: #c1c7d0 !default;
$neutral-60: #b3bac5 !default;
$neutral-70: #a5adba !default;
$neutral-80: #97a0af !default;
$neutral-90: #8993a4 !default;
$neutral-100: #7a869a !default;
$neutral-200: #6b778c !default;
$neutral-300: #5e6c84 !default;
$neutral-400: #505f79 !default;
$neutral-500: #42526e !default;
$neutral-600: #344563 !default;
$neutral-700: #253858 !default;
$neutral-800: #172b4d !default;
$neutral-900: #091e42 !default;

$red-50: #ffd6ca !default;
$red-75: #ffbdad !default;
$red-100: #ff8f73 !default;
$red-200: #ff7452 !default;
$red-300: #ff5630 !default;
$red-400: #de350b !default;
$red-500: #bf2600 !default;

$yellow-50: #fffae6 !default;
$yellow-75: #fff0b3 !default;
$yellow-100: #ffe380 !default;
$yellow-200: #ffc400 !default;
$yellow-300: #ffab00 !default;
$yellow-400: #ff991f !default;
$yellow-500: #ff8b00 !default;

$green-50: #e3fcef !default;
$green-75: #abf5d1 !default;
$green-100: #79f2c0 !default;
$green-200: #57d9a3 !default;
$green-300: #36b37e !default;
$green-400: #00875a !default;
$green-500: #006644 !default;

$purple-50: #eae6ff !default;
$purple-75: #c0b6f2 !default;
$purple-100: #998dd9 !default;
$purple-200: #8777d9 !default;
$purple-300: #6554c0 !default;
$purple-400: #5243aa !default;
$purple-500: #403294 !default;

$turquoise-50: #cbebe5 !default;
$turquoise-75: #aadfd5 !default;
$turquoise-100: #89d2c4 !default;
$turquoise-200: #46b9a3 !default;
$turquoise-300: #399886 !default;
$turquoise-400: #2d7668 !default;
$turquoise-500: #20554b !default;

// Syncfusion color overrides
$primary: $blue-300 !default;
$success: $green-300 !default;
// $info: $blue-dark !default;
$warning: $yellow-300 !default;
$danger: $red-400 !default;
$dark: $neutral-500 !default;

$logo-background-color: $primary !default;

@function shade-color($color, $percentage) {
  @return mix($black, $color, $percentage);
}

@function tint-color($color, $percentage) {
  @return mix($white, $color, $percentage);
}

$stepper-active-tab-color: $black !default;
$stepper-inactive-tab-color: $neutral-100 !default;

$workflow-disabled-fill-color: $neutral-60 !default;
$workflow-disabled-stroke-color: $neutral-60 !default;
$workflow-disabled-text-color: $neutral-40 !default;

$tab-active-color: $primary !default;
$tab-inactive-color: $neutral-500 !default;
$tab-inactive-border-color: $neutral-40 !default;
$tab-invalid-active-color: $red-500 !default;
$tab-invalid-color: $red-100 !default;

$field-border-color: $neutral-40 !default;
$field-background-color: $neutral-10 !default;
$field-placeholder-color: $neutral-100 !default;
$field-hover-color: $neutral-30 !default;
$field-active-color: $blue-100 !default;
$field-hint-color: $neutral-500 !default;
$field-disabled-background: $neutral-10 !default;
$field-disabled-color: $neutral-70 !default;

$select-border-color: $neutral-40 !default;
$select-background-color: $neutral-20 !default;
$select-hover-background-color: $primary !default;
$select-hover-color: $neutral-30 !default;
$select-disabled-background: $neutral-20 !default;
$select-disabled-color: $neutral-70 !default;
$select-icon-color: $neutral-500 !default;

$multiselect-chips-hover-background-color: $neutral-500 !default;
$multiselect-chips-color: $neutral-500 !default;
$multiselect-chips-background-color: $neutral-40 !default;

$checkbox-border-color: $neutral-40 !default;
$checkbox-background-color: $neutral-30 !default;
$checkbox-checked-background-color: $blue-400 !default;
$checkbox-checked-disabled: $neutral-20 !default;
$checkbox-checked-disabled-background-color: $neutral-70 !default;

$radio-border-color: $neutral-40 !default;
$radio-checked-background-color: $blue-400 !default;
$radio-checked-disabled: $neutral-20 !default;
$radio-checked-disabled-center-color: $neutral-70 !default;

$radio-checked-active-center-color: $blue-400 !default;
$radio-checked-active-background-color: $blue-50 !default;
$radio-checked-active-border-color: $blue-50 !default;

$radio-active-background-color: $neutral-30 !default;
$radio-active-border-color: $neutral-40 !default;
$radio-hover-background-color: $neutral-30 !default;
$radio-hover-border-color: $neutral-40 !default;
$radio-checked-hover-background-color: $blue-300 !default;

$date-hover-icon-color: $neutral-400 !default;
$date-icon-color: $neutral-500 !default;

$number-icon-color: $neutral-500 !default;

$toolbar-btn-hover-background-color: $blue-500 !default;
$toolbar-btn-active-background-color: $blue-500 !default;
$toolbar-icon-stroke: $primary !default;

$switch-on-active-background-color: $blue-400 !default;
$switch-on-active-color: $white !default;
$switch-on-hover-background-color: $blue-300 !default;
$switch-off-active-background-color: $neutral-200 !default;
$switch-off-active-color: $white !default;
$switch-off-hover-background-color: $neutral-70 !default;
$switch-handle-color: $white !default;

$breadcrumb-color: $neutral-900 !default;
$breadcrumb-active-color: $neutral-100 !default;
$breadcrumb-hover-color: $neutral-900 !default;
$breadcrumb-separator-color: $neutral-50 !default;

$filter-chip-color: $neutral-500 !default;
$filter-chip-hover-color: $blue-300 !default;
$filter-chip-background-color: $white !default;
$filter-active-chip-color: $white !default;
$filter-active-chip-background-color: $blue-500 !default;
$filter-add-background-color: $blue-300 !default;
$filter-add-color: $white !default;
$filter-delete-background-color: $white !default;
$filter-delete-color: $neutral-500 !default;
$filter-delete-border-color: $neutral-500 !default;
$filter-active-toggle-color: $blue-300 !default;
$filter-active-toggle-background-color: $blue-50 !default;
$filter-toggle-color: $neutral-500 !default;
$filter-toggle-background-color: $neutral-20 !default;

$sidebar-background-color: $white !default;
$sidebar-color: $black !default;
$sidebar-vertical-line-color: #e1e1e1 !default;
$sidebar-sub-items-color: #7d8282 !default;
$sidebar-hover-color: $primary !default;
$sidebar-node-active-color: $primary !default;
$sidebar-hamburger-stroke: $white !default;
$sidebar-settings-anchor-color: $black !default;

$profile-background-color: $white !default;
$profile-hover-color: $black !default;

$btn-primary-background-color: $primary !default;
$btn-primary-color: $white !default;
$btn-primary-hover-background-color: $blue-200 !default;
$btn-primary-pressed-background-color: $blue-400 !default;
$btn-primary-selected-background-color: $blue-500 !default;
$btn-primary-disabled-background-color: $neutral-20 !default;
$btn-primary-disabled-color: $neutral-70 !default;

$btn-dark-hover-background-color: $primary !default;
$btn-dark-pressed-background-color: $primary !default;

$btn-split-active-background-color: $primary !default;
$btn-split-active-color: $white !default;

$grid-icons-color: $white !default;
$grid-header-background-color: $primary !default;
$grid-header-color: $white !default;

$dialog-footer-primary-background-color: $primary !default;
$dialog-footer-primary-border-color: $primary !default;
$dialog-footer-primary-color: $white !default;
$dialog-footer-primary-hover-background-color: $blue-200 !default;

$spinner-stoke: $primary !default;

$tree-background-color: $white !default;
$tree-color: $black !default;
$tree-hover-color: $primary !default;
$tree-node-active-color: $primary !default;

// fonts

$f-8: 0.5rem;
$f-9: 0.5625rem;
$f-10: 0.625rem;
$f-11: 0.685rem;
$f-12: 0.75rem;
$f-14: 0.875rem;
$f-15: 0.9375rem;
$f-16: 1rem;
$f-20: 1.25rem;

$font-size-btn: $f-14;
$font-size-label: $f-14;
$font-size-input: $f-14;
$font-size-select: $f-14;
$font-size-hint: $f-12;
$font-size-asterisk: $f-14;
$font-size-error: $f-12;

$font-size-stepper-tab: $f-14;
$font-size-tab: $f-14;
$font-size-tab-content: $f-16;

$font-size-toast: $f-16;

$grid-pager: $f-12;

// line height
$line-height-btn: 1.625rem;
$line-height-outline-btn: 1.5rem;
$line-height-field: 1.5rem;
$line-height-label: 1rem;
$line-height-stepper-tab: 1.4rem;
$line-height-select: 1.5rem;

// Font weight
$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi: 600;
$font-weight-bold: 700;

$font-weight-btn: $font-weight-medium;
$font-weight-stepper-tab: $font-weight-medium;
$font-weight-tab: $font-weight-bold;

// remove this colors
$tab-border-color: #aaa;

$side-menu-border-color: #666;

$logo-color: rgba(255, 255, 255, 0.5);

$main-bg-color: $neutral-30;

// asol-panel

$asol-panel-bar-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
$asol-panel-bar-col-font-weight: $font-weight-bold;
$asol-panel-bar-col-font-size: $f-14;
$asol-panel-bar-col-line-height: 1rem;

$input-group-border-width: 2px;

//   0: $spacer * 0.25, // 4px
//   1: $spacer * 0.5, // 8px
//   2: $spacer * 0.75,  // 12px
//   3: $spacer,        // 16px
//   4: $spacer * 1.25,  // 20px
//   5: $spacer * 1.5,    // 24px
//   6: $spacer * 2,  // 32px
//   7: $spacer * 2.5,    // 40px
//   8: $spacer * 5,  // 80px
//   9: $spacer * 6.25,    // 100px
//   10: $spacer * 7.5,// 120px

$spacer: 1rem; //16px
$spacers: (
  none: 0,
  0: $spacer * 0.25,
  1: $spacer * 0.5,
  2: $spacer * 0.75,
  3: $spacer,
  4: $spacer * 1.25,
  5: $spacer * 1.5,
  6: $spacer * 2,
  7: $spacer * 2.5,
  8: $spacer * 5,
  9: $spacer * 6.25,
  10: $spacer * 7.5,
);

// Spacer function
@function spacer($spacer) {
  @return map-get($spacers, $spacer);
}

// Elevation

$elevation-shadow-1: rgba(0, 0, 0, 0.14);
$elevation-shadow-2: rgba(0, 0, 0, 0.12);
$elevation-shadow-3: rgba(0, 0, 0, 0.2);

// Elevation constant
$elevation-definition: (
  1: (
    'shadow-1': 0 1px 1px,
    'shadow-2': 0 2px 1px,
    'shadow-3': 0 1px 3px,
  ),
  2: (
    'shadow-1': 0 2px 2px,
    'shadow-2': 0 3px 1px,
    'shadow-3': 0 1px 5px,
  ),
  3: (
    'shadow-1': 0 3px 4px,
    'shadow-2': 0 3px 3px,
    'shadow-3': 0 1px 8px,
  ),
  4: (
    'shadow-1': 0 4px 5px,
    'shadow-2': 0 1px 10px,
    'shadow-3': 0 2px 4px,
  ),
  6: (
    'shadow-1': 0 6px 10px,
    'shadow-2': 0 1px 18px,
    'shadow-3': 0 3px 5px,
  ),
  8: (
    'shadow-1': 0 8px 10px,
    'shadow-2': 0 3px 14px,
    'shadow-3': 0 5px 5px,
  ),
  9: (
    'shadow-1': 0 9px 12px,
    'shadow-2': 0 3px 16px,
    'shadow-3': 0 5px 6px,
  ),
  12: (
    'shadow-1': 0 12px 17px,
    'shadow-2': 0 5px 22px,
    'shadow-3': 0 7px 8px,
  ),
  16: (
    'shadow-1': 0 16px 24px,
    'shadow-2': 0 6px 30px,
    'shadow-3': 0 8px 10px,
  ),
  24: (
    'shadow-1': 0 24px 38px,
    'shadow-2': 0 9px 46px,
    'shadow-3': 0 11px 15px,
  ),
);

// Elevation function
@function elevation($elevation) {
  $shadows: map-get($elevation-definition, $elevation);
  @return map-get($shadows, 'shadow-1') $elevation-shadow-1,
    map-get($shadows, 'shadow-2') $elevation-shadow-2,
    map-get($shadows, 'shadow-3') $elevation-shadow-3;
}
